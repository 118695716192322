module directives {
  export module master {
    interface IProductSKUScope extends ng.IScope {
      load();
      loadPackConfig(searchText: string);
      loadProductSize(searchText: string);
      loadProductColor(searchText: string);
      skuId: number;
      ownerEntityId: number;
      productSku: interfaces.master.IProductSKU;
      productSuppliers: Array<interfaces.master.IProductMasterSupplier>;
      selectedTab: number;
      gvwUoM: uiGrid.IGridOptions;
      apiUoM: uiGrid.IGridApi;
      gvwSuppliers: uiGrid.IGridOptions;
      apiSuppliers: uiGrid.IGridApi;
      insertUoM();
      loadUnits();
      setUoMRowDirty(entity: interfaces.master.IProductSKUUoM);
      insertSupplier();
      loadSuppliers();
      loadCurrencies();
      setSupplierRowDirty(entity: interfaces.master.IProductSKUSupplier);
      supplierList: Array<interfaces.applicationcore.IDropdownModel>;
      removeInlineUoM(entity: interfaces.master.IProductSKUUoM);
      removeInLineSupplier(entity: interfaces.master.IProductMasterSupplier);
    }

    export class productSKUDirective implements ng.IDirective {
      templateUrl =
        "templates/modules/applicationmain/master/productSKUView.html";
      scope = {
        productSku: "=?",
        skuId: "=",
        ownerEntityId: "=",
        productSuppliers: "="
      };

      messages: interfaces.applicationcore.IMessage[];

      constructor(
        private generalService: interfaces.applicationcore.IGeneralService,
        public $uibModal: ng.ui.bootstrap.IModalService,
        private hsCodeService: interfaces.tariff.IHsCodeService,
        private $state: ng.ui.IStateService,
        private bsLoadingOverlayService,
        private menuService: interfaces.applicationcore.IMenuService,
        public productService: interfaces.master.IProductService,
        private $timeout: ng.ITimeoutService,
        public packConfigurationService: interfaces.master.IPackConfigurationService,
        public productSizeService: interfaces.master.IProductSizeService,
        public productColourService: interfaces.master.IProductColourService,
        public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
        private supplierService: interfaces.master.ISupplierService,
        private currencyService: interfaces.applicationcore.ICurrencyService,
        private $q: ng.IQService
      ) {}

      link = (
        $scope: IProductSKUScope,
        $element: ng.IAugmentedJQuery,
        $state
      ) => {
        $scope.load = () => {
          $scope.gvwUoM.data = $scope.productSku.SKUUoMList;
          $scope.gvwSuppliers.data = $scope.productSku.SKUSupplierList;
          $scope.supplierList = _.map($scope.productSuppliers, o => {
            return o.Supplier;
          });
        };

        $scope.loadPackConfig = searchText => {
          return this.packConfigurationService.getDropdownList().query(
            {
              ownerEntityId: $scope.ownerEntityId,
              searchText: searchText
            },
            (
              data: ng.resource.IResourceArray<
                interfaces.applicationcore.IDropdownModel
              >
            ) => {},
            () => {}
          ).$promise;
        };

        $scope.loadProductSize = searchText => {
          return this.productSizeService.getDropdownList().query(
            { ownerEntityId: $scope.ownerEntityId },
            (
              data: ng.resource.IResourceArray<
                interfaces.applicationcore.IDropdownModel
              >
            ) => {},
            () => {}
          ).$promise;
        };

        $scope.loadProductColor = searchText => {
          return this.productColourService.getDropdownList().query(
            { ownerEntityId: $scope.ownerEntityId },
            (
              data: ng.resource.IResourceArray<
                interfaces.applicationcore.IDropdownModel
              >
            ) => {},
            () => {}
          ).$promise;
        };

        $scope.insertUoM = () => {
          $scope.productSku.SKUUoMList.push(<interfaces.master.IProductSKUUoM>{
            Id: 0,
            skuId: $scope.skuId,
            IsActive: true
          });
        };

        $scope.setUoMRowDirty = entity => {
          if ($scope.apiUoM) {
            $scope.apiUoM.rowEdit.setRowsDirty([entity]);
          }
        };

        $scope.loadUnits = () => {
          return this.unitOfMeasureService
            .getUoMDropdown()
            .query({ ownerEntityId: $scope.ownerEntityId }).$promise;
        };

        $scope.removeInlineUoM = (entity: interfaces.master.IProductSKUUoM) => {
          if (entity.Id === 0) {
            _.remove($scope.gvwUoM.data, o => {
              return o === entity;
            });

            if ($scope.apiUoM) {
              $scope.apiUoM.rowEdit.setRowsClean([entity]);
            }

            return;
          } else {
            entity.IsActive = false;
            $scope.apiUoM.rowEdit.setRowsDirty([entity]);
          }
        };

        $scope.removeInLineSupplier = (
          entity: interfaces.master.IProductMasterSupplier
        ) => {
          if (entity.Id === 0) {
            _.remove($scope.gvwSuppliers.data, o => {
              return o === entity;
            });

            if ($scope.apiSuppliers) {
              $scope.apiSuppliers.rowEdit.setRowsClean([entity]);
            }

            return;
          } else {
            entity.IsActive = false;
            $scope.apiSuppliers.rowEdit.setRowsDirty([entity]);
          }
        };

        $scope.gvwUoM = {
          data: [],
          enableFiltering: false,
          useExternalFiltering: false,
          enableCellEdit: true,
          useExternalSorting: false,
          multiSelect: false,
          enableColumnResizing: true,
          enableFullRowSelection: false,
          useExternalPagination: false,
          enableRowHeaderSelection: true,
          enableHorizontalScrollbar: 2,
          rowEditWaitInterval: -1,
          onRegisterApi: gridApi => {
            $scope.apiUoM = gridApi;
          },
          columnDefs: [
            {
              name: "EDIT",
              displayName: "",
              field: "Id",
              cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.removeInlineUoM(row.entity)" class="btn btn-warning btn-sm">
                                    Delete
                                </button>
                            </div>`,
              enableSorting: false,
              enableColumnMenu: false,
              enableCellEdit: false,
              width: 56,
              enableCellEditOnFocus: false,
              headerCellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.insertUoM()">
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>`
            },
            {
              name: "UoM",
              displayName: "Unit of Measure",
              field: "UoM",
              enableCellEditOnFocus: true,
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
              editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.loadUnits()"></gts-dropdown>
                                    </form>`,
              enableSorting: false,
              width: 140
            },
            {
              name: "Qty",
              field: "Quantity",
              displayName: "Qty",
              enableCellEditOnFocus: true,
              editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00001" min="0" />
                                        </p>
                                    </form>`,
              cellClass: "text-right",
              enableSorting: false,
              width: 75
            },
            ,
            {
              name: "Active",
              displayName: "Active",
              enableFiltering: false,
              enableCellEdit: true,
              enableCellEditOnFocus: true,
              cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-change="grid.appScope.setUoMRowDirty(row.entity)">`,
              editableCellTemplate: ` <form name="inputForm">
                                                        <p class="input-group-sm">
                                                            <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                        </p>
                                                    </form>`,
              width: 55
            }
          ]
        };

        $scope.insertSupplier = () => {
          $scope.productSku.SKUSupplierList.push(<
            interfaces.master.IProductSKUSupplier
          >{
            Id: 0,
            skuId: $scope.skuId,
            IsActive: true
          });
        };

        $scope.setSupplierRowDirty = entity => {
          if ($scope.apiUoM) {
            $scope.apiSuppliers.rowEdit.setRowsDirty([entity]);
          }
        };

        $scope.loadSuppliers = () => {
          return this.$q.resolve($scope.supplierList);
        };

        $scope.loadCurrencies = () => {

            return this.currencyService
            .getCurrencyDropdownList()
            .query().$promise;

         /* var defered = this.$q.defer<
            Array<interfaces.applicationcore.IDropdownModel>
          >();

          this.currencyService.getCurrencyDropdownList().query(
            (
              currencyList: Array<interfaces.applicationcore.IDropdownModel>
            ) => {
              defered.resolve(currencyList);
            },
            errorResponse => {
              this.generalService.displayMessageHandler(
                <interfaces.applicationcore.IMessageHandler>errorResponse.data
              );
              defered.resolve([]);
            }
          );

          return defered.promise;*/
        };

        $scope.gvwSuppliers = {
          data: [],
          enableFiltering: false,
          useExternalFiltering: false,
          enableCellEdit: true,
          useExternalSorting: false,
          multiSelect: false,
          enableColumnResizing: true,
          enableFullRowSelection: false,
          useExternalPagination: false,
          enableRowHeaderSelection: true,
          enableHorizontalScrollbar: 2,
          rowEditWaitInterval: -1,
          onRegisterApi: gridApi => {
            $scope.apiSuppliers = gridApi;
          },
          columnDefs: [
            {
              name: "EDIT",
              displayName: "",
              field: "Id",
              cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.removeInLineSupplier(row.entity)" class="btn btn-warning btn-sm">
                                    Delete
                                </button>
                            </div>`,
              enableSorting: false,
              enableColumnMenu: false,
              enableCellEdit: false,
              width: 56,
              enableCellEditOnFocus: false,
              headerCellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.insertSupplier()">
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>`
            },
            {
              name: "Supplier",
              displayName: "Supplier",
              field: "Supplier",
              enableCellEditOnFocus: true,
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
              editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown list="this.supplierList" append-to-body="true"  load-data="grid.appScope.loadSuppliers()" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}"  ng-model="MODEL_COL_FIELD" form="inputForm"></gts-dropdown>
                                    </form>`,
              enableSorting: false,
              width: 400
            },
            {
              name: "ItemCode",
              field: "SupplierItemCode",
              displayName: "Supplier Item Code",
              enableCellEditOnFocus: true,
              editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="text" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>
                                    </form>`,
              cellClass: "text-right",
              enableSorting: false,
              width: 200
            },
            {
              name: "SupplierPrice",
              field: "SupplierPrice",
              displayName: "Supplier Price",
              enableCellEditOnFocus: true,
              editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="number" ignore-wheel name="SupplierPrice" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00001" min="0" />
                                            </p>
                                        </form>`,
              cellClass: "text-right",
              enableSorting: false,
              width: 120
            },
            {
              name: "PriceCurrency",
              displayName: "Currency",
              field: "PriceCurrency",
              enableCellEditOnFocus: true,
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
              editableCellTemplate: `
                                        <form name="inputForm">
                                            <gts-dropdown  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.loadCurrencies()"></gts-dropdown>
                                        </form>`,
              enableSorting: false,
              width: 150
            },
            {
              name: "Active",
              displayName: "Active",
              enableFiltering: false,
              enableCellEdit: true,
              enableCellEditOnFocus: true,
              cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-change="grid.appScope.setSupplierRowDirty(row.entity)">`,
              editableCellTemplate: ` <form name="inputForm">
                                                        <p class="input-group-sm">
                                                            <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                        </p>
                                                    </form>`,
              width: 55
            }
          ]
        };

        $scope.load();
      };

      static factory(): ng.IDirectiveFactory {
        const directive = (
          generalService,
          $uibModal,
          hsCodeService,
          $state,
          bsLoadingOverlayService,
          menuService,
          productService,
          $timeout,
          packConfigurationService,
          productSizeService,
          productColourService,
          unitOfMeasureService,
          supplierService,
          currencyService,
          $q
        ) =>
          new productSKUDirective(
            generalService,
            $uibModal,
            hsCodeService,
            $state,
            bsLoadingOverlayService,
            menuService,
            productService,
            $timeout,
            packConfigurationService,
            productSizeService,
            productColourService,
            unitOfMeasureService,
            supplierService,
            currencyService,
            $q
          );
        directive.$inject = [
          "generalService",
          "$uibModal",
          "hsCodeService",
          "$state",
          "bsLoadingOverlayService",
          "menuService",
          "productService",
          "$timeout",
          "packConfigurationService",
          "productSizeService",
          "productColourService",
          "unitOfMeasureService",
          "supplierService",
          "currencyService",
          "$q"
        ];

        return directive;
      }
    }

    angular
      .module("app")
      .directive("gtsProductSku", productSKUDirective.factory());
  }
}
